import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";

export const Author = ({ image, name, subTitle = "" }) => {
  const authorImage = getImage(image) || image;

  return (
    <div className="columns is-vcentered is-mobile">
      <div className="column is-3 pr-0">
        <figure className="image">
          {typeof image === "object" ? (
            <GatsbyImage
              imgClassName="is-rounded has-background-light-gray"
              image={authorImage}
              alt={name}
            />
          ) : (
            <img
              src={authorImage}
              className="is-rounded has-background-light-gray"
              alt={name}
            />
          )}
        </figure>
      </div>
      <div className="column pl-2">
        <div
          className={`card-header-title pl-0 pr-0 pb-0${
            subTitle ? "" : "pt-0"
          }`}
        >
          <h1 className="title is-size-5-mobile is-size-5-tablet is-size-5-widescreen mb-0">
            {name}
          </h1>
        </div>

        {subTitle && (
          <div className="card-header-title pl-0 pr-0 pt-1">
            <h2 className="subtitle is-size-6-mobile is-size-6-tablet is-size-5-widescreen has-text-grey">
              {subTitle}
            </h2>
          </div>
        )}
      </div>
    </div>
  );
};
