import React from "react";
import PropTypes from "prop-types";
import { kebabCase } from "lodash";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import { TextBanner } from "../components/molecules/TextBanner";
import { Author } from "../components/molecules/Author";
import { LayoutBox } from "../components/atoms/LayoutBox";

// eslint-disable-next-line
export const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  title,
  author,
  date,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <>
      <TextBanner title={title} />
      <LayoutBox>
        <div className="columns is-vcentered pb-0">
          <div className="column is-4 pb-0">
            <Author name={author.name} image={author.image} subTitle={date} />
          </div>
        </div>
      </LayoutBox>
      <section className="section pt-0">
        <div className="container content">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <p style={{ fontStyle: "italic" }}>{description}</p>
              <PostContent content={content} />
              {tags && tags.length ? (
                <div style={{ marginTop: `4rem` }}>
                  <h4>Tags</h4>
                  <div className="tags">
                    {tags.map((tag) => (
                      <span key={tag + `tag`} className="tag is-small">
                        <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                      </span>
                    ))}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
  author: PropTypes.object,
};

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout
      title={`${post.frontmatter.title} | Blog`}
      description={post.frontmatter.description}
      imageURL={post.frontmatter.featuredimage?.publicURL}
      imageAlt={`Featured image for blog ${post.frontmatter.title}`}
    >
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        author={post.frontmatter.author}
        date={post.frontmatter.date}
      />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
        featuredimage {
          publicURL
        }
        author {
          name
          image {
            childImageSharp {
              gatsbyImageData(
                quality: 10
                width: 80
                layout: CONSTRAINED
                transformOptions: { grayscale: true }
              )
            }
          }
        }
      }
    }
  }
`;
